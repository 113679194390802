<script setup>
import {
  Bell,
  Building,
  Calendar,
  DollarSign,
  Home,
  LayoutDashboard,
  LogOut,
  MapPin,
  Settings,
  SquareParking,
  Ticket,
  Car,
  Menu,
  User,
  Users,
  X
} from 'lucide-vue-next';

const props = defineProps({
  pageTitle: {
    type: String,
    required: true,
    default: 'ADD PAGE TITLE'
  }
});

const { getUser } = useUser();
const isOpen = ref(false);
const navItems = ref([
  {
      title: 'Book A Spot',
      url: '/search',
      icon: MapPin,
      isActive: getUser.role !== 'CPO',
    },
    {
      title: 'My Reservations',
      url: '/dashboard',
      icon: Calendar,
      isActive: true,
    },
    {
      title: 'Facilities',
      url: '/dashboard/facilities',
      icon: Building,
      isActive: getUser.role === 'CPO',
    },
    {
      title: 'Lots',
      url: '/dashboard/lots',
      icon: SquareParking,
      isActive: getUser.role === 'CPO',
    },
    {
      title: 'Team',
      url: '/dashboard/team',
      icon: Users,
      isActive: getUser.role === 'CPO',
    },
    {
      title: 'My Wallet',
      url: '/dashboard/wallet',
      icon: DollarSign,
      isActive: getUser.role === 'PARKER',
    },
    {
      title: 'My Vehicles',
      url: '/dashboard/vehicles',
      icon: Car,
      isActive: getUser.role === 'PARKER',
    },
    {
      title: 'Discount Parking',
      url: '/parking-packs',
      icon: Ticket,
      isActive: getUser.role !== 'CPO',
    },
    {
      title: 'Home',
      url: '/',
      icon: Home,
      isActive: true,
    },
]);

const toggleSidebar = () => {
  isOpen.value = !isOpen.value;
};

const handleLinkClick = (item) => {
  if (window.innerWidth < 768) {
    isOpen.value = false;
  }
};

onMounted(() => {
  window.addEventListener('resize', () => {
    if (window.innerWidth <= 768) {
      isOpen.value = false;
    } else {
      isOpen.value = true;
    }
  })
  isOpen.value = window.innerWidth >= 768;
});
</script>

<template>
<div class="flex h-screen">
  <!-- Sidebar -->
  <div
    :class="[
      'fixed inset-y-0 left-0 z-50 w-64 transform bg-primary dark:bg-black shadow-lg transition-transform duration-300 md:relative md:translate-x-0 flex flex-col',
      isOpen ? 'w-full md:w-64' : '-translate-x-full'
    ]"
  >
    <!-- Sidebar Header -->
    <div class="flex items-center justify-between p-2 bg-primary dark:bg-black">
      <NuxtLink to="/" class="flex items-center">
        <img src="/images/spotsurferLogo.png" alt="Logo" class="h-12 w-12 mr-1" />
        <!-- Hide when collapsed -->
        <div class="flex flex-col">
          <span class="text-3xl font-semibold text-white uppercase">
            Spotsurfer
          </span>
          <span class="text-white text-xs tracking-widest uppercase group-has-[[data-collapsible=icon]]/sidebar-wrapper:hidden">Reserve. Park. Play.</span>
        </div>
      </NuxtLink>

      <button
        @click="toggleSidebar"
        v-if="isOpen"
        class="absolute top-2 right-2 p-2 rounded-md md:hidden text-white"
      >
        <X class="h-6 w-6" />
      </button>
    </div>

    <!-- Navigation Links -->
    <nav class="p-2">
      <ul class="space-y-2">
        <li v-for="(item, index) in navItems.filter((item) => item.isActive)" :key="index">
          <a
            :href="item.url"
            @click="handleLinkClick(item)"
            :class="[
              'flex items-center p-2 text-white hover:text-accent-yellow'
            ]"
          >
            <component :is="item.icon" class="h-5 w-5 mr-3" />
            <span>{{ item.title }}</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="mt-auto">
      <DropdownMenu>
        <DropdownMenuTrigger as-child  class="flex w-full items-center justify-between p-4">
          <button>
            <span class="flex h-6 w-6 items-center mr-2">
              <User class="stroke-white" v-if="!getUser?.avatar" />
              <NuxtImg class="h-full w-full rounded-full object-cover" :src="getUser?.avatar" v-else />
            </span>
            <div class="grid flex-1 text-left text-sm leading-tight">
              <span class="truncate font-semibold text-white">{{ getUser?.first_name }} {{ getUser?.last_name }}</span>
              <span class="truncate text-xs text-white">{{ getUser?.email }}</span>
            </div>
            <Settings class="ml-2 size-4 text-white" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side="bottom" align="end" :side-offset="4">
          <DropdownMenuLabel class="p-0 font-normal">
            <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
              <div class="grid flex-1 text-left text-sm leading-tight">
                <span class="truncate font-semibold">{{ getUser?.first_name }} {{ getUser?.last_name }}</span>
                <span class="truncate text-xs">{{ getUser?.email }}</span>
              </div>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem class="p-0">
              <NuxtLink to="/dashboard/settings" class="flex items-center w-full p-2" @click="handleLinkClick">
                <Settings class="mr-2" />
                Settings
              </NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem class="p-0">
              <NuxtLink to="/dashboard/support" class="flex items-center w-full p-2" @click="handleLinkClick">
                <Bell class="mr-2" />
                Support
              </NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem v-if="getUser?.is_staff" class="p-0">
              <NuxtLink to="/admin" class="flex items-center w-full p-2" @click="handleLinkClick">
                <LayoutDashboard class="mr-2" />
                Admin Dashboard
              </NuxtLink>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem @click="useLogout" class="cursor-pointer">
            <LogOut class="mr-2"/>
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </div>

  <!-- Main Content -->
  <div class="flex-1 flex flex-col overflow-hidden">
    <!-- Top Navigation -->
    <header class="bg-white dark:bg-background z-10">
      <div class="flex justify-end p-4">
        <button @click="toggleSidebar">
          <Menu :size="32" class="stroke-primary hover:stroke-accent-yellow md:hidden" />
        </button>
      </div>
    </header>

    <!-- Content Area -->
    <main class="flex-1 overflow-y-auto">
      <div class="max-w-7xl mx-auto">
        <h1 class="px-6 text-3xl font-bold">{{ pageTitle }}</h1>
        <slot />
      </div>
    </main>
  </div>

  <!-- Overlay for mobile -->
  <div
    v-if="isOpen"
    @click="toggleSidebar"
    class="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
  ></div>
</div>
</template>
