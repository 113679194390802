<script setup>
import Sidebar from '@/components/custom/Sidebar';

const route = useRoute();
const title = computed(() => (route.meta.title || 'Dashboard Layout'));
</script>

<template>
  <Sidebar :pageTitle="title">
    <div class="flex min-h-screen w-full flex-col">
      <div class="flex flex-col sm:gap-4 sm:py-4 ">
        <section class="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <slot />
        </section>
      </div>
    </div>
  </Sidebar>
</template>
